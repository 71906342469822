module.exports = [{
      plugin: require('/codebuild/output/src2387114206/src/bitbucket.org/simformteam/simform-website-redesign-2019/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://1d207eb35c59402a9b98b33417dc09cd@sentry.io/1539692","ignoreErrors":[{}],"sampleRate":0,"environment":"production","enabled":true},
    },{
      plugin: require('/codebuild/output/src2387114206/src/bitbucket.org/simformteam/simform-website-redesign-2019/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P2CFCC3","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function() {\n          return {\n            pageType: window.pageType,\n          }\n        }"}},
    },{
      plugin: require('/codebuild/output/src2387114206/src/bitbucket.org/simformteam/simform-website-redesign-2019/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
